@tailwind base;
@tailwind components;
@tailwind utilities;

.checkbox {
  @apply flex;
}

.checkbox:not(:disabled) {
  @apply cursor-pointer;
}

.checkbox:hover > .checkbox__input:not(:checked) + .checkbox__svg-wrapper--default {
  @apply bg-neutral-100;
}

.checkbox:hover > .checkbox__input:not(:checked) + .checkbox__svg-wrapper--white {
  @apply bg-opacity-40;
}

.checkbox:hover > .checkbox__input:checked:not(:disabled) + .checkbox__svg-wrapper--default {
  @apply bg-purple-100;
}

.checkbox:hover > .checkbox__input:checked:not(:disabled) + .checkbox__svg-wrapper--white {
  @apply bg-opacity-90;
}

.checkbox__input {
  @apply absolute h-6 w-6 opacity-0 cursor-pointer;
}

.checkbox__input:disabled {
  @apply cursor-not-allowed;
}

.checkbox__input:checked + .checkbox__svg-wrapper--default {
  @apply border-purple-500 bg-purple-50;
}

.checkbox__input:checked + .checkbox__svg-wrapper--white {
  @apply border-white bg-opacity-90;
}

.checkbox__input:checked + div .checkbox__svg {
  @apply block;
}

.checkbox__input:disabled + .checkbox__svg-wrapper--default,
.checkbox__input:disabled + .checkbox__svg-wrapper--white {
  @apply border-neutral-300 bg-neutral-100;
}

.checkbox__svg-wrapper--default,
.checkbox__svg-wrapper--white {
  @apply
  mr-2
  flex
  h-6
  w-6
  flex-shrink-0
  items-center
  justify-center
  rounded-lg
  border-2;
}

.checkbox__svg-wrapper--default {
  @apply border-neutral-900 bg-neutral-50;
}

.checkbox__svg-wrapper--white {
  @apply border-white bg-white bg-opacity-30;
}

.checkbox__svg {
  @apply pointer-events-none hidden h-3 w-3;
}

.checkbox__label {
  @apply cursor-pointer;
}
