@tailwind base;

.rhap_container {
  @apply flex flex-col gap-2 bg-purple-700 text-white p-6;
}

@media only screen and (min-width: 768px) {
  .rhap_container {
    @apply flex-row gap-0;
  }
}

.rhap_stacked-reverse .rhap_controls-section {
  @apply mb-4;
}

.rhap_play-pause-button {
  @apply w-12 h-12;
}

.rhap_volume-controls {
  @apply items-center;
}

.rhap_volume-controls:hover .rhap_volume-bar {
  @apply bg-white;
}

.rhap_volume-controls:hover svg path {
  stroke: #fff;
}

.rhap_volume-bar {
  @apply h-0.5 bg-neutral-200 bg-opacity-50;
}

.rhap_volume-indicator {
  @apply bg-white opacity-100;
  top: -5px;
}

.rhap_volume-filled {
  @apply bg-white;
}

.rhap_time {
  @apply text-purple-200;
  width: 45px;
}

.rhap_progress-container {
  @apply mx-4;
}

.rhap_progress-bar {
  @apply h-0.5 bg-neutral-200 bg-opacity-50;
}

.rhap_progress-indicator {
  @apply h-3 w-3 bg-white;
  top: -5px;
}

.rhap_progress-filled {
  @apply bg-white;
}
