@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Recoleta";
  src: url("/fonts/Recoleta-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Recoleta";
  src: url("/fonts/Recoleta-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Recoleta";
  src: url("/fonts/Recoleta-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Recoleta";
  src: url("/fonts/Recoleta-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("/fonts/DM-Sans-Regular.ttf");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("/fonts/DM-Sans-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("/fonts/DM-Sans-Bold.ttf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  src: url("/fonts/DM-Sans-Italic.ttf");
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Maradona Signature";
  src: url("/fonts/Maradona-Signature.woff2");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

/**
 * Centra font family
 */
@font-face {
  font-family: "Centra";
  src: url("/fonts/CentraNo1/CentraNo1-Book.ttf");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra";
  src: url("/fonts/CentraNo1/CentraNo1-BookItalic.ttf");
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Centra";
  src: url("/fonts/CentraNo1/CentraNo1-Bold.ttf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Centra";
  src: url("/fonts/CentraNo1/CentraNo1-BoldItalic.ttf");
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Centra";
  src: url("/fonts/CentraNo1/CentraNo1-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Centra";
  src: url("/fonts/CentraNo1/CentraNo1-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Centra";
  src: url("/fonts/CentraNo1/CentraNo1-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Centra";
  src: url("/fonts/CentraNo1/CentraNo1-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

/**
 * Recife font family
 */
@font-face {
  font-family: "Recife";
  src: url("/fonts/Recife/Recife-Bold.ttf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Recife";
  src: url("/fonts/Recife/Recife-BoldItalic.ttf");
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Recife";
  src: url("/fonts/Recife/Recife-Book.ttf");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Recife";
  src: url("/fonts/Recife/Recife-BookItalic.ttf");
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Recife";
  src: url("/fonts/Recife/Recife-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Recife";
  src: url("/fonts/Recife/Recife-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Recife";
  src: url("/fonts/Recife/Recife-Regular.ttf");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Recife";
  src: url("/fonts/Recife/Recife-RegularItalic.ttf");
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

html,
body,
body > div:first-child,
div#__next {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

/**
 * Set the first div min-height to be 100% to fix the issue where some properties of
 * overflowed items were being cut off (like background color and borders).
 */
div#__next > div:first-child {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

/**
 * Remove Chrome's default autofill background color
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 1) inset !important;
  -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 1) inset !important;
}

/* Hide browser's default datepicker on basic HTML date inputs */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
