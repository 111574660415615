.box {
  position: relative;
  padding: 1rem;
  z-index: 2;
  overflow: hidden;
  color: white;
}

.box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  padding: 1px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.box::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("/img/onboarding/noise.png");
  border-radius: 1rem;
  opacity: 0.1;
  padding: 2px;
  z-index: 1;
  mix-blend-mode: multiply;
}

.purpleBorder::before {
  background: linear-gradient(145deg, #C8A6EE, rgba(0, 0, 0, 0) 70%);
}

.tealBorder::before {
  background: linear-gradient(145deg, #A0E8ED, rgba(0, 0, 0, 0) 50%);
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}