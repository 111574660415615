:root {
  --color-neutral-50: #f9f9f9;
  --color-neutral-100: #f1f1f2;
  --color-neutral-300: #d1d0d6;
  --color-neutral-500: #9a97a4;
  --color-neutral-600: #6d697e;
  --color-neutral-900: #1c1831;
  --color-purple-50: #f3eefd;
  --color-purple-100: #eae1fb;
  --color-white: #fff;

  --font-family-dm-sans: "DM Sans", sans-serif;
  --font-family-centra: "Centra", sans-serif;

  --font-size-xs: 12px;
  --font-size-base: 16px;

  --font-weight-regular: 400;
  --font-weight-bold: 700;

  --line-height-base: 1.5;

  --rounded-md: 8px;
}

/* DTP is the DateTimePicker component */
.OlivaAvailabilityViewer,
.DTP {
  font-family: "DM Sans", sans-serif;
  color: #787c90;
  margin-bottom: 16px !important;
}

.OlivaAvailabilityViewer__label--day {
  width: 64px !important;
}

/* Do not show the confirmation button after saving the availability rules */
.OlivaAvailabilityViewer__confirmation {
  display: none !important;
}

.OlivaAvailabilityViewer__submit {
  background-color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #546bcf !important;
  color: #232946 !important;
}

.OlivaAvailabilityViewer__timezone {
  display: none !important;
}

/* Hide the title and description for the calendar sync component */

.AvailabilityViewer__title {
  display: none !important;
}

.AvailabilityViewer__status--active {
  border: none !important;
}

.AvailabilityViewer__status--active p {
  display: none;
}

/* Customize the buttons of the calendar sync component */

.AvailabilityViewer__providers {
  flex-direction: column;
}

.AvailabilityViewer__provider-auth {
  background-color: #f6f6f6 !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 8px !important;
  min-width: 250px;
  text-align: left;
  width: 100% !important;
}

.AvailabilityViewer__provider-auth-text {
  text-align: left !important;
}

/* Update the color of the timezone select component */

.OlivaTimezoneSelect__single-value {
  color: #787c90 !important;
}

/**
 * Availability Viewer
 *
 * This is being used to prevent click interactions on the /admin/professional-availability page
 * since it's currently a read-only view.
 */
.CronofyAvailabilityViewer__body {
  pointer-events: none !important;
}

/**
 * DateTimePicker
 */
.Oli.DTP,
.Oli-Minimal.DTP,
.Oli-Minimal-With-Time.DTP {
  font-family: var(--font-family-dm-sans);
  font-size: var(--font-size-base);
  line-height: 1.5;
  margin-bottom: 0;
}

/**
 * DateTimePicker details (timezone select wrapper)
 */
.Oli-Minimal__details,
.Oli-Minimal-With-Time__details {
  display: none !important;
}

/**
 * DateTimePicker grid
 */

.Oli-Minimal__column--left,
.Oli-Minimal-With-Time__column--left {
  flex: 2 !important;
}

.Oli-Minimal__column--right,
.Oli-Minimal-With-Time__column--right {
  flex: 1 !important;
}


/**
 * Month (e.g. "January", "February", etc.)
 */
.Oli__calendar-header--title,
.Oli-Minimal__calendar-header--title,
.Oli-Minimal-With-Time__calendar-header--title {
  color: var(--color-neutral-900) !important;
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-bold) !important;
  line-height: var(--line-height-base);
  text-transform: capitalize !important;
}

/**
 * Arrows to navigate between months
 */
.Oli__calendar-header-button:not(:disabled) svg,
.Oli-Minimal__calendar-header-button:not(:disabled) svg,
.Oli-Minimal-With-Time__calendar-header-button:not(:disabled) svg {
  fill: var(--color-neutral-900) !important;
}

.Oli__calendar-header-button:disabled svg,
.Oli-Minimal__calendar-header-button:disabled svg,
.Oli-Minimal-With-Time__calendar-header-button:disabled svg {
  fill: var(--color-neutral-300) !important;
}

/**
 * Day of the week (e.g. "Mon", "Tue", etc.)
 */
.Oli__calendar-grid--week-day,
.Oli-Minimal__calendar-grid--week-day,
.Oli-Minimal-With-Time__calendar-grid--week-day {
  color: var(--color-neutral-600) !important;
  font-size: var(--font-size-xs) !important;
  font-weight: var(--font-weight-bold) !important;
  line-height: var(--line-height-base);
  letter-spacing: 0.1rem;
  text-transform: capitalize !important;
}

/**
 * Day of the month (e.g. "1", "2", etc.)
 */
.Oli__calendar-grid--button,
.Oli-Minimal__calendar-grid--button,
.Oli-Minimal-With-Time__calendar-grid--button {
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-regular) !important;
  border: 1px solid transparent !important;
}

.Oli__calendar-grid--button:disabled,
.Oli-Minimal__calendar-grid--button:disabled,
.Oli-Minimal-With-Time__calendar-grid--button:disabled {
  color: var(--color-neutral-500) !important;
}

.Oli__calendar-grid--prevnext:disabled,
.Oli-Minimal__calendar-grid--prevnext:disabled,
.Oli-Minimal-With-Time__calendar-grid--prevnext:disabled {
  color: var(--color-neutral-500) !important;
}

.Oli__calendar-grid--available,
.Oli-Minimal__calendar-grid--available,
.Oli-Minimal-With-Time__calendar-grid--available {
  background-color: var(--color-white) !important;
  border: none !important;
  border-radius: var(--rounded-md) !important;
  color: var(--color-neutral-900) !important;
}

.Oli__calendar-grid--available:not(.DTP__calendar-grid--selected):hover,
.Oli-Minimal__calendar-grid--available:not(.DTP__calendar-grid--selected):hover,
.Oli-Minimal-With-Time__calendar-grid--available:not(.DTP__calendar-grid--selected):hover {
  background-color: var(--color-neutral-100) !important;
  border: 1px solid var(--color-neutral-100) !important;
  box-shadow: none !important;
}

.Oli__calendar-grid--selected,
.Oli__calendar-grid--focused,
.Oli-Minimal__calendar-grid--selected,
.Oli-Minimal-With-Time__calendar-grid--selected,
.Oli-Minimal__calendar-grid--focused,
.Oli-Minimal-With-Time__calendar-grid--focused {
  background-color: var(--color-purple-50) !important;
  border: 1px solid var(--color-purple-50) !important;
  box-shadow: none !important;
  font-weight: var(--font-weight-regular) !important;
}

/**
 * Time slots header
 */
.Oli__time-slots-list--header,
.Oli-Minimal__time-slots-list--header,
.Oli-Minimal-With-Time__time-slots-list--header {
  color: var(--color-neutral-900) !important;
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-bold) !important;
  line-height: var(--line-height-base);
  text-transform: capitalize !important;
  text-align: left !important;
}

/**
 * Time slots list
 */

.Oli__time-slots-group--wrapper,
.Oli-Minimal__time-slots-group--wrapper,
.Oli-Minimal-With-Time__time-slots-group--wrapper {
  height: auto !important;
  max-height: 340px !important;
  margin-top: 32px !important;
  overflow-y: auto !important;
}

.Oli__time-slots-group--wrapper::-webkit-scrollbar,
.Oli-Minimal__time-slots-group--wrapper::-webkit-scrollbar,
.Oli-Minimal-With-Time__time-slots-group--wrapper::-webkit-scrollbar {
  display: none;
}

.Oli__slot-list--item,
.Oli-Minimal__slot-list--item,
.Oli-Minimal-With-Time__slot-list--item {
  margin-top: 8px !important;
  width: 100% !important;
}

.Oli__time-slot,
.Oli-Minimal__time-slot,
.Oli-Minimal-With-Time__time-slot {
  background-color: var(--color-neutral-50) !important;
  border-radius: var(--rounded-md) !important;
  border: 1px solid var(--color-neutral-50) !important;
  color: var(--color-neutral-900) !important;
  font-size: var(--font-size-base) !important;
  line-height: var(--line-height-base);
  padding: 12px 16px !important;
  text-align: left !important;
  display: flex !important;
  justify-content: space-between !important;
}

/**
 * On the minimal version, we don't allow any interaction with the time slots as they're read-only
 */
.Oli-Minimal__time-slot {
  pointer-events: none !important;
}

.Oli__time-slot:not(.DTP__time-slot--selected):hover,
.Oli-Minimal__time-slot:not(.DTP__time-slot--selected):hover,
.Oli-Minimal-With-Time__time-slot:not(.DTP__time-slot--selected):hover {
  background-color: var(--color-neutral-100) !important;
  border: 1px solid var(--color-neutral-100) !important;
}

.Oli__time-slot--selected,
.Oli-Minimal__time-slot--selected,
.Oli-Minimal-With-Time__time-slot--selected {
  background-color: var(--color-purple-50) !important;
  border: 1px solid var(--color-purple-50) !important;
  font-weight: var(--font-weight-bold) !important;
}

.Oli__time-slot--selected:after,
.Oli-Minimal__time-slot--selected:after,
.Oli-Minimal-With-Time__time-slot--selected:after {
  content: "✓" !important;
}

.Oli__time-slot--selected:hover,
.Oli-Minimal__time-slot--selected:hover,
.Oli-Minimal-With-Time__time-slot--selected:hover {
  background-color: var(--color-purple-100) !important;
  border: 1px solid var(--color-purple-100) !important;
}
