
@tailwind utilities;

@layer utilities {
  .rdp-max-width-7 {
    max-width: calc(45px * 7);
  }
  .rdp-max-width-8 {
    max-width: calc(45px * 8);
  }
  .rpd-head-weekeday-h {
    height: calc(var(--rdp-cell-size) - 5);
  }
}

.rdp {
  --rdp-cell-size: 45px;
  @apply p-2 flex w-full font-normal;
}

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
  clip: rect(1px, 1px, 1px, 1px) !important;
  @apply box-border p-0 m-0 bg-transparent !border-none appearance-none !absolute top-0 !w-[1px] !h-[1px] !overflow-hidden;
}

/* Buttons */
.rdp-button_reset {
  @apply appearance-none relative m-0 p-0 cursor-default text-inherit bg-none;
}

.rdp-button_reset:focus-visible {
  /* Make sure to reset outline only when :focus-visible is supported */
  @apply outline-none;
}

.rdp-button {
  @apply border-2 border-transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  @apply opacity-100 text-neutral-500 cursor-not-allowed;
}

.rdp-button:not([disabled]) {
  @apply cursor-pointer;
}

.rdp-button:focus-visible:not([disabled]) {
  @apply text-inherit bg-white border-2 border-purple-500;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  @apply bg-neutral-50;
}

.rdp-button:focus:not([disabled]):not(.rdp-day_selected) {
  @apply bg-neutral-100;
}

.rdp-button:focus-visible:not([disabled]):not(.rdp-day_selected) {
  @apply bg-white border-2 border-purple-900;
}

.rdp-months {
  @apply flex w-full flex-1;
}

.rdp-month {
  /* margin: 0 1em; */
  @apply my-1 flex w-full flex-col;
}

.rdp-month:first-child {
  @apply ml-0;
}

.rdp-month:last-child {
  @apply mr-0;
}

.rdp-table {
  @apply m-0 rdp-max-width-7 border-collapse flex w-full flex-col;
}

.rdp-with_weeknumber .rdp-table {
  @apply rdp-max-width-8 border-collapse;

}

.rdp-caption {
  @apply flex items-center px-1 mb-2 text-left text-neutral-900;
}

.rdp-multiple_months .rdp-caption {
  @apply relative text-center block;
}

.rdp-caption_dropdowns {
  @apply inline-flex relative;
}

.rdp-caption_label {
  @apply relative z-40 w-4/5 inline-flex align-middle items-center m-0 px-1 py-[0.25em] whitespace-nowrap border-2 border-transparent text-inherit font-bold text-lg;
}

.rdp-nav {
  @apply flex whitespace-nowrap px-1 ;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  @apply absolute top-[50%] left-0 -translate-y-[50%];
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  @apply absolute top-[50%] right-0 -translate-y-[50%];
}

.rdp-nav_button {
  @apply inline-flex items-center justify-center w-[var(--rdp-cell-size)] h-[var(--rdp-cell-size)] p-[0.25em] rounded-md;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
  @apply relative inline-flex align-middle items-center;
}

.rdp-dropdown {
  @apply appearance-none absolute z-40 top-0 bottom-0 left-0 w-full m-0 p-0 opacity-0 border-none bg-transparent text-inherit;
}

.rdp-dropdown[disabled] {
  @apply opacity-0 text-transparent;
}


.rdp-dropdown_icon {
  @apply ml-1;
}

.rdp-head {
  @apply border-0 flex w-full;
}

.rdp-head_row {
  @apply h-full flex w-full;
}
.rdp-row {
  @apply h-full ;
}

.rdp-head_cell {
  @apply align-middle text-xs capitalize text-neutral-600 font-bold text-center rpd-head-weekeday-h w-[var(--rdp-cell-size)] p-0 ;
}

.rdp-tbody {
  @apply w-full  border-0;
}

.rdp-tfoot {
  @apply flex w-full m-[0.5em];
}

.rdp-cell {
  @apply w-[var(--rdp-cell-size)] h-[var(--rdp-cell-size)] p-0 text-center font-normal;
}

.rdp-weeknumber {
  @apply text-xs;
}

.rdp-weeknumber,
.rdp-day {
  @apply flex overflow-hidden items-center justify-center box-border w-[var(--rdp-cell-size)] h-[var(--rdp-cell-size)] m-0 border-2 border-transparent rounded-lg;
}

.rdp-day_today:not(.rdp-day_outside) {
  @apply font-bold !text-neutral-500;
}

.rdp-day_today.rdp-day_disabled {
  @apply !text-neutral-500 !font-bold opacity-100 text-lg;
}

.rdp-day_selected {
  @apply opacity-100 bg-purple-50;
}

.rdp-button.rdp-day_selected {
  @apply opacity-100 bg-purple-50;
}

.rdp-day_selected:hover {
  @apply opacity-100 bg-purple-100;
}

.rdp-day_outside {
  @apply opacity-50;
}

.rdp-day_selected:focus-visible {
  @apply z-40 outline-offset-2 outline-purple-500;
}

.rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
  @apply rounded-tr-none rounded-br-none;
}

.rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
  @apply rounded-tl-none rounded-bl-none;

}

.rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
  @apply rounded-tl-none rounded-bl-none;
}

.rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
  @apply rounded-tr-none rounded-br-none;
}

.rdp-day_range_end.rdp-day_range_start {
  @apply rounded-full;
}

.rdp-day_range_middle {
  @apply rounded-none;
}
