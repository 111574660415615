@tailwind base;
@tailwind components;
@tailwind utilities;

.counter-dot__wrapper {
  @apply flex h-6 w-6 items-center justify-center;
}

.counter-dot {
  @apply inline-flex h-5 w-5 items-center justify-center rounded-full;
}
