.oli-ui-table {
  @apply w-full;
}

.oli-ui-table thead {
  @apply bg-neutral-100 font-bold text-neutral-900;
}

.oli-ui-table thead td:first-child {
  @apply rounded-tl-lg;
}

.oli-ui-table thead td:last-child {
  @apply rounded-tr-lg;
}

.oli-ui-table tr {
  @apply border-b border-b-neutral-200;
}

.oli-ui-table td {
  @apply px-2 py-4;
}
